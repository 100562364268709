/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Container } from './styles';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  lineBreaks?: boolean;
};

type Ref = HTMLTextAreaElement;

const TextareaAutoResizable = React.forwardRef<Ref, Props>(
  ({ onChange, style = {}, onKeyDown, lineBreaks = true, ...props }, ref) => {
    const innerRef = React.useRef<HTMLTextAreaElement>(null);
    const [text, setText] = React.useState('');
    const [textAreaHeight, setTextAreaHeight] = React.useState('auto');
    const [parentHeight, setParentHeight] = React.useState('auto');

    React.useEffect(() => {
      if (innerRef.current) {
        setParentHeight(`${innerRef.current.scrollHeight}px`);
        setTextAreaHeight(`${innerRef.current.scrollHeight}px`);
      }
    }, [text]);

    const onChangeHandler = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaHeight('auto');
        if (innerRef.current) {
          setParentHeight(`${innerRef.current.scrollHeight}px`);
        }
        setText(event.target.value);

        if (onChange) {
          onChange(event);
        }
      },
      [onChange],
    );

    const onKeyDownHandler = React.useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !lineBreaks) {
          event.preventDefault();
        }

        if (onKeyDown) {
          onKeyDown(event);
        }
      },
      [lineBreaks, onKeyDown],
    );

    React.useImperativeHandle(
      ref,
      () => innerRef.current as HTMLTextAreaElement,
      [],
    );

    return (
      <Container
        style={{
          minHeight: parentHeight,
        }}
      >
        <textarea
          {...props}
          ref={innerRef}
          style={{
            ...style,
            height: textAreaHeight,
          }}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
        />
      </Container>
    );
  },
);

export default TextareaAutoResizable;
