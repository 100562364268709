import styled from 'styled-components';

import { HeadingSmall } from '@/design/typography';
import colors from '@/styles/colors';

export const Container = styled.div`
  background: #fff;
  border: 1px solid ${colors.secondaryLite};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  min-width: 400px;

  position: absolute;
  top: 31px;
  left: 0;

  z-index: 10000;

  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const Title = styled.div`
  ${HeadingSmall(18)};
`;

export const HeaderFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  row-gap: 24px;

  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
`;

export const ProtocoloInfoRow = styled.div`
  display: flex;
  flex-direction: row;

  column-gap: 42px;

  justify-content: flex-start;
`;

export const Atualizacao = styled.div``;
export const Versao = styled.div``;

export const ProtocoloInfoLabel = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
`;

export const ProtocoloInfoText = styled.span`
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: ${colors.hardGray};
`;

export const Body = styled.div`
  display: contents;
  max-height: 65vh;
  overflow-y: auto;
`;

export const AutorCard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CoautoresCard = styled(AutorCard)``;

export const AutorTitle = styled.h3`
  font-size: 16px;
  color: #333333;
  margin-bottom: 8px;
`;

export const AutorInfo = styled.div`
  display: flex;
  align-items: flex-start;

  img,
  span {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    object-fit: cover;
  }
`;

export const AutorDetail = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: ${props => (props.alignCenter ? 'center' : 'auto')};
`;

export const AutorDetailItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const AutorDetailItemTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 2px;
`;

export const AutorDetailItemSubtitle = styled.p`
  font-size: 14px;
  color: ${colors.hardGray};
`;

export const SectionTitle = styled(AutorTitle)``;
