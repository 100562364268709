import React from 'react';

import { ReactComponent as OutIllustration } from '@/assets/illustrations/feedback-graphics/out.svg';

import Button from '@/components/Button';
import { SelectionControlOption } from '@/components/SelectionControlInput';

import { closeMedflowApp } from '@/utils/window';

import ExecucaoAPI from '@/features/execucao/services/ExecucaoAPI';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';

import RadioAlternatives from '@/components/RadioAlternatives';

import {
  Container,
  InfoContent,
  FeedbackQuestion,
  StatusReason,
  OtherHelpText,
  SendButton,
} from './styles';

type FeedbackOption = SelectionControlOption<string | number>;
type JustificativaPayload =
  | {
      texto: string;
    }
  | {
      modelo: number;
    };
const SugestoesDefault: FeedbackOption[] = [
  {
    value: 'nao_estou_seguro',
    label: 'Não estou seguro com o protocolo',
  },
  {
    value: 'protocolo_incompativel',
    label: 'Protocolo não é compatível com os sintomas do paciente',
  },
  {
    value: 'protocolo_extenso',
    label: 'Protocolo é muito extenso',
  },
  {
    value: 'outro',
    label: 'Outro',
    editable: true,
  },
];

const otherAlternative = SugestoesDefault[SugestoesDefault.length - 1];
const { value: otherKey } = otherAlternative;

const Banner = () => {
  return (
    <InfoContent>
      <OutIllustration />
      <h3>Você abandonou o protocolo.</h3>

      <p>
        Nos conte o que houve para que possamos melhorar sua experiência no
        Medflow.
      </p>
    </InfoContent>
  );
};

const ReadOnlyBanner = () => {
  return (
    <InfoContent>
      <OutIllustration />
      <h3>Protocolo abandonado.</h3>

      <p>O fluxo do protocolo foi abandonado pelo médico responsável.</p>
    </InfoContent>
  );
};

const FeedbackQuestionnaire: React.FC = () => {
  const [closing, setLoading] = React.useState(false);
  const [feedback, setFeedback] = React.useState<FeedbackOption>();
  const [focusErrors, setFocusErrors] = React.useState(false);
  const [alternativesTouched, setAlternativesTouched] = React.useState(false);

  const { user } = useAuth();
  const [{ mode }] = useEnterpriseContext();

  const [
    { protocoloExecutado },
    { dispatchConfirmarSaida },
  ] = useExecucaoContext();

  const [options, setOptions] = React.useState(() => {
    if (user && user.empresa) {
      const sugestoes: FeedbackOption[] = [
        ...user.empresa.sugestoes_justificativa.map(jus => ({
          label: jus.texto,
          value: jus.id,
        })),
        SugestoesDefault[SugestoesDefault.length - 1],
      ];

      if (sugestoes.length > 1) {
        return sugestoes;
      }
    }

    return SugestoesDefault;
  });

  const feedbackContainErrors = React.useCallback((reason: FeedbackOption) => {
    const { label, value } = reason;

    if (value === otherKey) {
      return label.toString().length < 10;
    }

    return false;
  }, []);

  const close = React.useCallback(() => {
    if (mode === 'integrated' && protocoloExecutado) {
      closeMedflowApp(protocoloExecutado);
    } else {
      dispatchConfirmarSaida();
    }
  }, [dispatchConfirmarSaida, mode, protocoloExecutado]);

  const enviarJustificativa = React.useCallback(async () => {
    if (feedback && protocoloExecutado) {
      if (feedbackContainErrors(feedback)) {
        setFocusErrors(true);
        return;
      }

      setLoading(true);
      let justificativa: JustificativaPayload;

      if (typeof feedback.value === 'string') {
        // Send free text
        justificativa = {
          texto: feedback.label as string,
        };
      } else {
        // Send alternative ID
        justificativa = {
          modelo: feedback.value,
        };
      }

      await ExecucaoAPI.justificarStatusProtocolo(
        protocoloExecutado.id.toString(),
        justificativa,
      );

      setLoading(false);
    }

    close();
  }, [close, feedback, feedbackContainErrors, protocoloExecutado]);

  const otherLabel = React.useCallback((text: string) => {
    setOptions(prev =>
      prev.map(opt => ({
        ...opt,
        label: opt.value === otherKey ? text : opt.label,
      })),
    );
  }, []);

  const resetOtherIfEmpty = React.useCallback(() => {
    let isOtherLabelEmpty = false;

    setOptions(prev =>
      prev.map(opt => {
        if (opt.value === otherKey) {
          isOtherLabelEmpty = opt.label.toString().trim() === '';
          if (isOtherLabelEmpty) {
            return otherAlternative;
          }
        }

        return opt;
      }),
    );

    if (isOtherLabelEmpty) {
      setAlternativesTouched(false);
    }
  }, []);

  return (
    <>
      <FeedbackQuestion>
        <p>Porque você abandonou o protocolo?</p>

        <RadioAlternatives
          value={feedback?.value}
          horizontal
          onAlternativesChange={changed => {
            setAlternativesTouched(true);
            // Update feedback content
            setFeedback(prev =>
              changed.find(({ value }) => value === prev?.value),
            );
            setOptions(changed);
            setFocusErrors(false);
          }}
          onAlternativeSelect={option => {
            if (option.value === otherKey) {
              if (!alternativesTouched) {
                otherLabel('');
              }
            } else {
              resetOtherIfEmpty();
            }

            setFeedback(option);
            setFocusErrors(false);
          }}
          options={options}
        />

        {feedback && feedback.value === otherKey && (
          <OtherHelpText
            hasError={focusErrors}
            itemIndex={options.findIndex(opt => opt.value === otherKey)}
          >
            Mínimo 10 caracteres
          </OtherHelpText>
        )}
      </FeedbackQuestion>

      <SendButton
        loading={closing}
        theme="tertiary"
        onClick={enviarJustificativa}
        disabled={!feedback}
      >
        Enviar e fechar
      </SendButton>
    </>
  );
};

const FeedbackQuestionnaireResponse = () => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const reason = React.useMemo(() => {
    if (protocoloExecutado && protocoloExecutado.justificativa_status) {
      return protocoloExecutado.justificativa_status.texto;
    }

    return '';
  }, [protocoloExecutado]);

  const closeTab = React.useCallback(() => {
    if (protocoloExecutado) {
      closeMedflowApp(protocoloExecutado);
    }
  }, [protocoloExecutado]);

  return (
    <>
      {reason && (
        <StatusReason>
          <strong>Motivo:</strong>
          <p>{reason}</p>
        </StatusReason>
      )}

      <Button theme="tertiary" onClick={() => closeTab()}>
        Fechar
      </Button>
    </>
  );
};

const ProtocoloInterrompidoEnterprise: React.FC<{
  hideContent?: boolean;
}> = () => {
  const [{ isProtocoloReadOnly }] = useExecucaoContext();

  const Content = React.useMemo(() => {
    if (isProtocoloReadOnly) {
      return (
        <>
          <ReadOnlyBanner />
          <FeedbackQuestionnaireResponse />
        </>
      );
    }

    return (
      <>
        <Banner />
        <FeedbackQuestionnaire />
      </>
    );
  }, [isProtocoloReadOnly]);

  return <Container>{Content}</Container>;
};

export default ProtocoloInterrompidoEnterprise;
