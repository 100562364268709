import React from 'react';

import { SelectionControlOption } from '../SelectionControlInput';

import {
  AlternativesContainer,
  AlternativeInput,
  AlternativeInputWrapper,
} from './styles';
import { SelectionControlType } from '../SelectionControl/types';

export interface SelectionControlAlternativesProps<TValue> {
  options: SelectionControlOption<TValue>[];
  selected: TValue[];
  onAlternativeSelect?: (option: SelectionControlOption<TValue>) => void;
  onAlternativesChange?: (options: SelectionControlOption<TValue>[]) => void;
  disabled?: boolean;
  selectionType?: SelectionControlType;
  style?: React.CSSProperties;
  className?: string;
  horizontal?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectionControlAlternatives = <TValue extends unknown>({
  options,
  selected,
  onAlternativeSelect,
  onAlternativesChange,
  disabled = false,
  style,
  className,
  horizontal = true,
  selectionType = 'radio',
}: SelectionControlAlternativesProps<TValue>) => {
  const [maxHeight, setMaxHeight] = React.useState(0);
  const optionsRef = React.useRef<(HTMLDivElement | null)[]>([]);

  const horizontallyWrapped = React.useMemo(() => {
    return horizontal && options.every(op => !op.details) && maxHeight <= 56;
  }, [horizontal, maxHeight, options]);

  const handleAlternativesChange = React.useCallback(
    (changedOption: SelectionControlOption<TValue>, label: string) => {
      const editedOtion = { ...changedOption, label, edited: true };

      if (onAlternativesChange) {
        onAlternativesChange(
          options.map(option =>
            option.value === changedOption.value ? editedOtion : option,
          ),
        );
      }

      // if (onAlternativeSelect) {
      //   onAlternativeSelect(editedOtion);
      // }
    },
    [onAlternativesChange, options],
  );

  const handleAlternativeClick = React.useCallback(
    (option: SelectionControlOption<TValue>) => {
      if (onAlternativeSelect) {
        onAlternativeSelect(option);
      }

      // cancelOpenedEditions(option);
    },
    [onAlternativeSelect],
  );

  React.useEffect(() => {
    setMaxHeight(
      Math.max(
        ...optionsRef.current.map(
          (r: HTMLDivElement | null) => r?.clientHeight || 0,
        ),
      ),
    );
  }, [optionsRef]);

  return (
    <AlternativesContainer
      style={style}
      className={className}
      horizontal={horizontallyWrapped}
    >
      {options.map((option, index) => (
        <AlternativeInputWrapper
          key={`${option.value}`}
          horizontal={horizontallyWrapped}
          ref={element => {
            optionsRef.current[index] = element;
          }}
        >
          <AlternativeInput
            disabled={disabled}
            option={option}
            marked={selected.includes(option.value)}
            onClick={() => handleAlternativeClick(option)}
            selectionType={selectionType}
            onOptionLabelChange={label =>
              handleAlternativesChange(option, label)
            }
          />
        </AlternativeInputWrapper>
      ))}
    </AlternativesContainer>
  );
};

export default SelectionControlAlternatives;
