import React from 'react';

import Button from '@/components/Button';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { AutoriaProtocolo as AutoriaProtocoloInterface } from '@/models/AutoriaProtocolo';

import Avatar from '@/assets/avatar-default.svg';

import ImageLoader from '@/components/ImageLoader';

// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';

// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import {
  ActionContainer,
  Atualizacao,
  AutorCard,
  AutorDetail,
  AutorDetailItem,
  AutorDetailItemSubtitle,
  AutorDetailItemTitle,
  AutorInfo,
  AutorTitle,
  Body,
  CoautoresCard,
  Container,
  Header,
  HeaderFooter,
  ProtocoloInfoLabel,
  ProtocoloInfoRow,
  ProtocoloInfoText,
  SectionTitle,
  Title,
  Versao,
} from './styles';

interface AutoriaProtocoloProps {
  autoria: AutoriaProtocoloInterface;
}

const AutoriaProtocolo: React.FC<AutoriaProtocoloProps> = ({ autoria }) => {
  const { dispatchExibirFontesProtocolo } = useExecucaoContext()[1];

  const ultima_atualizacao = React.useMemo(
    () =>
      format(parseISO(autoria.ultima_atualizacao), 'dd/MM/yyyy', {
        locale: ptBR,
      }),
    [autoria.ultima_atualizacao],
  );

  return (
    <Container>
      <Header>
        <Title>Detalhes do Protocolo</Title>
        <HeaderFooter>
          <ProtocoloInfoRow>
            <Atualizacao>
              <ProtocoloInfoLabel>Última atualização</ProtocoloInfoLabel>
              <ProtocoloInfoText>{ultima_atualizacao}</ProtocoloInfoText>
            </Atualizacao>
            {autoria?.versao && (
              <Versao>
                <ProtocoloInfoLabel>Versão</ProtocoloInfoLabel>
                <ProtocoloInfoText>{autoria.versao}</ProtocoloInfoText>
              </Versao>
            )}
          </ProtocoloInfoRow>
        </HeaderFooter>
      </Header>
      {!!autoria.autor && (
        <Body>
          <AutorCard>
            <AutorTitle>Autor</AutorTitle>
            <AutorInfo>
              <ImageLoader
                src={autoria.autor?.imagem_url || Avatar}
                alt="doctor"
              />
              <AutorDetail>
                <AutorDetailItem>
                  <AutorDetailItemTitle>
                    {autoria.autor?.nome}
                  </AutorDetailItemTitle>
                  <AutorDetailItemSubtitle>
                    {autoria.autor?.especialidade &&
                      `${autoria.autor?.especialidade} | `}

                    {autoria.autor?.conselho}
                  </AutorDetailItemSubtitle>
                </AutorDetailItem>
                {autoria.autor?.formacao && (
                  <AutorDetailItem>
                    <AutorDetailItemTitle>Formação:</AutorDetailItemTitle>
                    <AutorDetailItemSubtitle>
                      {autoria.autor?.formacao}
                    </AutorDetailItemSubtitle>
                  </AutorDetailItem>
                )}
                {autoria.autor?.atuacoes && (
                  <AutorDetailItem>
                    <AutorDetailItemTitle>Atuações:</AutorDetailItemTitle>
                    <AutorDetailItemSubtitle>
                      {autoria.autor?.atuacoes}
                    </AutorDetailItemSubtitle>
                  </AutorDetailItem>
                )}
              </AutorDetail>
            </AutorInfo>
          </AutorCard>

          {!!autoria.co_autores.length && (
            <CoautoresCard>
              <SectionTitle>Coautores</SectionTitle>
              {autoria.co_autores?.map(coAutor => {
                return (
                  <AutorCard key={coAutor.id}>
                    <AutorInfo>
                      <ImageLoader
                        src={coAutor?.imagem_url || Avatar}
                        alt="doctor"
                      />
                      <AutorDetail alignCenter>
                        <AutorDetailItem>
                          <AutorDetailItemTitle>
                            {coAutor.nome}
                          </AutorDetailItemTitle>
                          <AutorDetailItemSubtitle>
                            {coAutor?.especialidade &&
                              `${coAutor?.especialidade} | `}

                            {coAutor?.conselho}
                          </AutorDetailItemSubtitle>
                        </AutorDetailItem>
                      </AutorDetail>
                    </AutorInfo>
                  </AutorCard>
                );
              })}
            </CoautoresCard>
          )}
        </Body>
      )}
      {!!autoria.fontes.length && (
        <ActionContainer>
          <Button
            theme="secondary"
            onClick={() => {
              dispatchExibirFontesProtocolo(true);
            }}
          >
            Ver fontes
          </Button>
        </ActionContainer>
      )}
    </Container>
  );
};

export default AutoriaProtocolo;
